import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
   },

   {
        path: '/serve/:id',
        name: 'Serve',
        component: () => import('@/views/Serve')
   },
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/News.vue')
    },
    {
        path: '/newsDetails/:id',
        name: 'newsDetails',
        component: () => import('@/views/newsDetails.vue')
    },

    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue')
    },


]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },
})


//路由点击跳转后页面回到顶部
router.afterEach(() => {

    window.scrollTo(0, 0);
});

export default router
