<template>
<div class="header" id="header">

  <div class="mobile">
    <div class="m-menu" >
      <div class="hamburger-menu" @click="toggleSidebar">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </div>
    </div>
    <div class="m-logo">
      <router-link to="/">
        <img src="@/assets/img/logo.png">
      </router-link>
    </div>
  </div>
  <div class="block"></div>

  <div class="sile" :class="{ open: sidebarOpen }">
    <div class="left" v-if="show">
      <div class="content">
        <!--logo-->
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/img/logo.png">
          </router-link>
        </div>
        <!--navBar-->
        <div class="navBar">
          <ul>
            <li>
              <router-link to="/" :class="{'active': $route.name === 'Home'}" @click="mobileClick">
                {{ $t("home") }}
              </router-link>
            </li>
            <li>
              <router-link to="/about" :class="{'active': $route.name === 'About'}" @click="mobileClick">
                {{ $t("about") }}
              </router-link>
            </li>
            <li class="nav-have" @mouseover="showSubMenu = true" @mouseleave="showSubMenu = false">
              <div>
                <router-link :to="`/serve/${defaultId}`"  :class="{'active': $route.name === 'Serve'}">
                  {{ $t("serve") }} <i class="iconfont icon-youjiantou"></i>
                </router-link>
              </div>
              <div class="main-panel" v-if="showSubMenu">
                <ul>
                  <li v-for="item in serveCate" :key="item.id" @click="mobileClick">
                    <a href="javascript:;" @click="newMore(item.id);" :class="{ 'current': item.id === currentItemId }">
                      <span class="s-nav-text" >{{ item.title }}</span>
                    </a>
                  </li>

                </ul>
              </div>
            </li>
            <li>
              <router-link to="/news" :class="{'active': $route.name === 'News' || $route.name === 'newsDetails'}" @click="mobileClick">
                {{ $t("news") }}
              </router-link>
            </li>
            <li>
              <router-link to="/contact" :class="{'active': $route.name === 'Contact'}" @click="mobileClick">
                {{ $t("contact") }}
              </router-link>
            </li>
          </ul>
        </div>
        <!--lang-->
        <div class="lang">
          <a @click="changeLanguage('hk')" :class="{active:language === 'hk'}">繁體中文</a> 丨
          <a @click="changeLanguage('en')"  :class="{active:language === 'en'}">ENG</a>
        </div>

      </div>
    </div>
  </div>


</div>

</template>

<script>
import i18n from "@/lang/index";
import { ref} from 'vue';
import {getServiceCate} from "@/network/main";

export default {
  name: "Header",
  setup() {
    const sidebarOpen = ref(false);
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };
    const mobileClick = () => {
      sidebarOpen.value = false; // 点击时隐藏 side
    };
    return {
      sidebarOpen,
      toggleSidebar,
      mobileClick
    };
  },
  data() {
    return {
      language:localStorage.getItem("language") == null ? 'hk' :localStorage.getItem("language"),
      show: true,
      isShow:true,
      serveCate:[],
      currentItemId: null,
      showSubMenu: false,
      defaultId:1
   }
  },
  created() {
    this.getServiceCate();
  },
  methods: {
    //分类
    getServiceCate() {
      getServiceCate().then(res => {
        this.serveCate = res.data.data
        if (this.serveCate.length > 0) {
          this.defaultId = this.serveCate[0].id;
        }
      })
    },
    //
    newMore(id){
      this.currentItemId = id;
      this.$router.push('/serve/' + id)
    },
   //语言切换
    changeLanguage(language) {
      localStorage.setItem("language", language)

      if (language == "en") {
        i18n.global.locale = "en";
        localStorage.setItem('language',"en")
        console.log(i18n.global.locale)
        location.reload();
      }  else if (language == "hk") {
        i18n.global.locale = "hk";
        localStorage.setItem('language',"hk")
        location.reload();
      }
    },
  },
}

</script>

<style scoped>
.left{
  width: calc(20% - 0px);
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  padding: 60px 30px;
  z-index: 210;
  height: 100%;
  box-shadow: 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  text-align: center;
}
.content{
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 100%;
}
.logo img{
  max-height: 200px;
}
.navBar{
  padding: 30px 0;
}
.navBar ul li{
  padding: 15px 0;
  font-weight: bold;
}
.navBar ul li a.active{
  color: var(--background);
}
.nav-have{
  position: relative;
}

.main-panel {
  min-width: 140px;
  position: absolute;
  top: 0;
  left: 80%;
  background-color: #fff;
  padding: 10px;
  display: none;
  box-shadow: 0 0  15px #ddd;
}

.nav-have:hover .main-panel {
  display: block;
}

.main-panel ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.main-panel ul li{
  font-weight: normal;
}
.lang{
  font-size: 14px;
}
.lang a{
  color: #666;
  cursor: pointer;
}
.lang a.active{
  color: var(--background);
}
.mobile{
  display: none;
}
span.s-nav-text{
  cursor: pointer;
}
.current{
  color: var(--background);
}

@media only screen and (min-width:0px) and (max-width:1024px){
  .left{
    padding: 30px;
  }
}

@media only screen and (min-width:0px) and (max-width:767px){

  .sile{
    display: none;
  }
  .sile.open {
    display: block;
  }
  .left{
    width: 100%;
    align-items: flex-start;
  }
  .left .logo{
    display: none;
  }
  .hamburger-menu {
    display: inline-block;
    width: 28px;
    height: 20px;
    cursor: pointer;
    position: relative;
  }
  .line {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #333;
    position: absolute;
    left: 0;
    transition: transform 0.3s ease;
  }
  .line:nth-child(1) {
    top: 0;
  }
  .line:nth-child(2) {
    top: 8px;
  }
  .line:nth-child(3) {
    top: 16px;
  }
  .hamburger-menu.open .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger-menu.open .line:nth-child(2) {
    opacity: 0;
  }
  .hamburger-menu.open .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .mobile{
    width: 100%;
    position: fixed;
    display: block;
    padding: 10px 0;
    z-index:5;
    background: #fff;
  }
  .m-menu{
    position:absolute;
    left: 20px;
    bottom: 10px;
    z-index: 9999;
  }
 .m-logo{
   display: flex;
   align-items: center;
   justify-content: center;
}
  .m-logo img{
     width: 180px;
  }
  .block{
     height: 85px;
  }
.main-panel{
   position: relative;
   left: 0;
  top: 10px;
  box-shadow: none;
}
}

</style>