<template>
<div>
  <div class="c-item">
    <input type="text" class="input" :placeholder="$t('name')" v-model="name">
  </div>
  <div class="c-item">
    <input type="text" class="input" :placeholder="$t('email')" v-model="email">
  </div>
  <div class="c-item">
    <input type="text" class="input" :placeholder="$t('phone')" v-model="phone">
  </div>
  <div class="c-item">
    <textarea class="textarea" :placeholder="$t('message')" v-model="message"></textarea>
  </div>
  <div class="common-btn">
      <div class="common-btn-txt submit-btn" @click="sendForm">
        {{ $t("submit") }}
      </div>
  </div>
</div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

</template>

<script>
import {form} from "@/network/main";

export default {
  name: "form",
  data(){
    return{
      name:'',
      email:'',
      phone:'' ,
      message:'',
      isPopupVisible: false,
      popupText: '',
    }
  },
  methods: {
    validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email || !emailRegex.test(this.email)) {
        this.isPopupVisible = true;
        this.popupText = this.$t("tips01");
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return false;
      }
      return true; // 表单验证通过
    },
    // 验证输入框中必须包含数字
    validateInput() {
      const regex = /\d/;
      if (!regex.test(this.phone)) {
        this.isPopupVisible = true;
        this.popupText = this.$t("tips05");
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return false;
      }
      return true; // 验证通过
    },
    sendForm() {
      //驗證必填
      if (!this.name || !this.email || !this.phone || this.message=== 0) {
        this.isPopupVisible = true
        this.popupText=this.$t('tips02')
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }
      const formData = new FormData();
      if (this.email) {
        if (!this.validateForm()) {
          return;
        }
        formData.append('email', this.email);
      }

      if (this.phone) {
        if (!this.validateInput()) {
          return;
        }
        formData.append('phone', this.phone);
      }

      formData.append('name', this.name);
      formData.append('message', this.message);

      // 发送表单数据
      form(formData).then((res)=>{
        if(res.data.code==200){
          this.isPopupVisible = true
          this.popupText=this.$t('tips03')
          setTimeout(() => {
            this.isPopupVisible = false;
            this.name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
            this.$router.push('/');
          }, 2000);
        }else {
          this.popupText=this.$t('tips04')
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err=> {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>
.c-item{
  width: 100%;
  margin-bottom: 10px;
}
.c-item .input{
  width: 100%;
  background: #fff;
  border: 1px solid #bbb;
  -webkit-appearance: none;
}
.c-item .textarea{
  width: 100%;
  border: 1px solid #bbb;
}
.submit-btn{
  margin-top: 10px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
}
</style>