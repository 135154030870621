<template>
  <div class="loading-c">
    <div class="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.loading-c {
  width: 100%;
  height: 100vh;
  background: #ddd;
  position: fixed;
  left: 20%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.loading {
  width: 70px;
  height: 40px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-40%, -50%);
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.loading span {
  width: 8px;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: load 1s ease infinite;
  animation: load 1s ease infinite;
}
@-webkit-keyframes load {
  0%,
  100% {
    height: 40px;
    background: #fff;
  }
  50% {
    height: 70px;
    margin: -15px 0;
    /*background: lightgreen;*/
    background: var(--background);
  }
}
@keyframes load {
  0%,
  100% {
    height: 40px;
    background: #fff;
  }
  50% {
    height: 70px;
    margin: -15px 0;
    /*background: lightgreen;*/
    background: var(--background);
  }
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .loading-c{
    left: 0;
  }
}



</style>