<template>
  <div class="copyright">
    <p>© {{ currentYear }} 灝盛環境服務有限公司. All Rights Reserved. </p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  data(){
    return{
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style scoped>
.copyright{
  color: #717378;
  background: #E4E4E4;
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
}
</style>