import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueLazyLoad from 'vue3-lazyload'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import i18n from './lang'

createApp(App).use(router).use(i18n).use(VueViewer)
    .use(VueLazyLoad, {
        // loading: require('@/assets/img/loading.gif'), // 图片加载时默认图片
        // error: require('@/assets/img/error.png')// 图片加载失败时默认图片
    }).mount('#app')



// createApp(App).use(router).mount('#app')
