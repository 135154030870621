<template>
  <Header/>
  <keep-alive>
    <router-view :key="$route.fullPath" v-if="$route.meta.keepalive"/>
  </keep-alive>
  <router-view :key="$route.fullPath" v-if="!$route.meta.keepalive"/>


</template>
<script>
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {
    Header
  },
}
</script>

<style>
@import "assets/font/iconfont.css";
@import "assets/css/main.css";
</style>
