import {request} from "@/network/request";

//index
export function  getIndexInfo() {
    return request({
        url: 'home',
    })
}
//about
export function  getAboutInfo() {
    return request({
        url: 'about_us',
    })
}
//serve
export function  getServiceCate() {
    return request({
        url: 'service',
    })
}
//serve-more
export function  getServiceDetails(id) {
    return request({
        url: 'service_details/'+ id,
  })
}
//news
export function  getNews(page) {
    return request({
        url: '/new_message_list',
        params:{
            page
        }
    })
}
//news-more
export function  getNewsDetails(id) {
    return request({
        url: 'new_message_details/'+ id,
    })
}
//Contact
export function  getContact() {
    return request({
        url: 'contact_us',
    })
}

// //----------------post请求-----------------//
//form
export function  form(params) {
    return request({
        url: 'visitor',
        method: 'post',
        data: params,
    })
}












