
import axios from 'axios'

export function request(config) {
    //1.创建axios的实例
    const instance = axios.create( {
        baseURL: 'https://api.haosheng.com.hk/api/',
    })

    //2.1请求拦截
    instance.interceptors.request.use(config =>{
        // config.headers = {'Content-Disposition':'attachment'}

        // 默认语言
        const language = localStorage.getItem('language') || 'hk'

        if (language == "hk") {
            config.headers = {'language':'1'}
        }else{
            config.headers = {'language':'2'}
        }

        return config
    }, err =>{
        console.log(err)
    });

    //2.2 响应拦截  一定要记得返回!!!!
    instance.interceptors.response.use(res =>{
        return res
    }, err =>{
        console.log(err)
    });


    //3.发送网络请求
    return instance(config)

}
