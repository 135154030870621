<template>

<div class="page-wrapper">
  <Loading v-if="loading"></Loading>
   <div class="big-banner">
     <div class="big-txt">
       <h1>{{ bannerTitle }}</h1>
     </div>
     <img :src="banner">
   </div>
  <div class="index-about common-padding">
    <div class="container">
      <h1 class="common-title">{{ $t("about") }}</h1>
      <div v-html="editorDescription"></div>
    </div>
  </div>
  <div class="index-serve common-padding bg" :style="{ 'background-image': 'url(' + serveBg + ')' }">
     <div class="container">
       <h1 class="common-title" style="color: #fff;">{{ $t("serve") }}</h1>
       <div class="index-serve-list">
         <ul>
           <li v-for="item in list" :key="item.id" @click="details(item.id)">
             <h4>{{item.title}}</h4>
             <p>{{item.sketch}}</p>
           </li>
         </ul>
       </div>
       <div class="common-btn">
         <router-link to="/contact">
           <div class="common-btn-txt">
             {{ $t("quote") }}
           </div>
         </router-link>
       </div>
     </div>
  </div>
  <div class="index-contact common-padding bg" :style="{ 'background-image': 'url(' + contactBg + ')' }">
    <div class="container">
       <h1 class="common-title" style="color: #fff;">{{ $t("contact") }}</h1>
      <div class="index-c-content">
      <div class="index-c-form">
         <Form></Form>
      </div>
        <div class="index-c-info">
         <div>
           <span>
              <i class="iconfont icon-dizhi"></i>
           </span>
           <span>{{ address }}</span>
         </div>
          <div>
            <span>
              <i class="iconfont icon-shizhong"></i>
            </span>
             <span>{{ time }}</span>
          </div>
          <div>
            <span>
              <i class="iconfont icon-tel"></i>
            </span>
            <span>{{ phone }}</span>
          </div>
          <div>
            <span>
              <i class="iconfont icon-chuanzhen"></i>
            </span>
            <span>{{ fax }}</span>
          </div>
          <div>
            <span>
              <i class="iconfont icon-email-fill"></i>
            </span>
            <span>{{ email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Copyright></Copyright>
</div>
</template>

<script>
import Form from "@/components/form";
import Copyright from "@/components/Copyright";
import Loading from "@/components/loading";
import {getIndexInfo} from "@/network/main";

export default {
  name: "Home",
  components:{
    Form,
    Copyright,
    Loading
  },
  data(){
    return{
      loading:true,
      banner:'',
      bannerTitle:'',
      aboutTitle:'',
      aboutContent:'',
      serveBg:'',
      list:[],
      address:'',
      time:'',
      phone:'',
      fax:'',
      email:'',
      contactBg:'',
    }
  },
  created() {
    this.getIndexInfo();
  },
  methods:{
    //首页
    getIndexInfo() {
      getIndexInfo().then(res =>{
        this.loading = false;
        const data = res.data.data
        this.banner = data.banner.home_banner;
        this.bannerTitle = data.banner.home_banner_title;
        this.aboutTitle = data.about_us.title;
        this.aboutContent = data.about_us.description;
        this.list = data.service;
        this.serveBg = data.service_bg;
        this.address = data.contact_us.address;
        this.time = data.contact_us.working_hours;
        this.phone = data.contact_us.phone;
        this.fax = data.contact_us.fax;
        this.email = data.contact_us.email;
        this.contactBg =data.contact_us.contact_us_bg;
      })
    },
   //
    details(id){
      this.$router.push('/serve/' + id)
    },
  },
  computed: {
    editorDescription() {
      if (this.aboutContent) {
        return this.aboutContent.replace(/\r?\n/g, "<br>");
      }
      return "";
    },
  }

}
</script>

<style scoped>
.big-banner{
  position: relative;
}
.big-txt{
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-txt h1{
  text-align: center;
   font-size: 48px;
   color: #fff;
  font-weight: normal;
}
.index-about{
  background-color: rgb(203, 233, 196);
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-about div{
  text-align: center;
}

.index-about p{
  padding: 5px 0;
}
.bg{
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-position: 50% 50%;
  position: relative;
  z-index: 0;
}
.index-serve:before {
  content: "";
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(69, 102, 61, 0.35);
  transform-style: preserve-3d;
  z-index: -1;
}

.index-serve-list ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 4;
}

.index-serve-list ul li{
   width: 48%;
  border: 1px solid #fff;
  padding: 30px;
  margin: 20px 0;
  color: #fff;
  z-index: 4;
  cursor: pointer;
}

.index-serve-list ul li h4{
  padding-bottom: 20px;
}
.index-c-content{
  display: flex;
  justify-content: space-between;
}
.index-c-form{
  width: 50%;
}
.index-c-info{
  width: 45%;
}
.index-c-info>div{
  display: flex;
  align-items: center;
  color: #fff;
  padding-bottom: 20px;
}

.index-c-info>div span:first-child{
   padding-right: 10px;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .big-txt h1{
    font-size: 24px;
  }
  .index-serve-list ul li{
    width: 100%;
    margin: 10px 0;
  }
  .index-c-content{
    flex-wrap: wrap;
  }
  .index-c-form{
    width: 100%;
    order: 2;
    /*  換位*/
  }
  .index-c-info{
    width: 100%;
    order: 1;
  }
}



</style>