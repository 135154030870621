export default {
    home:"首頁",
    about:"關於我們",
    serve: "服務",
    news:"最新消息",
    contact:"聯絡我們",
    quote:"獲取報價",
    submit:"提交",
    name:"姓名",
    email:"電子郵件",
    phone:"電話號碼",
    message:"訊息",
    tips01:"請填寫正確的郵件格式",
    tips02:"請填寫完表格",
    tips03:"郵件發送成功！",
    tips04:"郵件發送失敗！",
    tips05:"電話號碼必須包含數字",
}