export default {
    home: "Home",
    about: "About Us",
    serve: "Services",
    news: "News",
    contact: "Contact Us",
    quote: "Get a Quote",
    submit: "Submit",
    name: "Name",
    email: "Email",
    phone: "Phone Number",
    message: "Message",
    tips01: "Please enter the correct email format",
    tips02: "Please fill out the form completely",
    tips03: "Email sent successfully!",
    tips04: "Email sending failed!",
    tips05: "Phone number must contain digits",
}